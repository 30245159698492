import React, { useEffect, useState } from 'react';
import { USERID } from '../App'
import { Link } from 'react-router-dom';

export default function MusicList(props) {
  const [songs, setSongs] = useState([])
  const [filtered, setFiltered] = useState([])

  useEffect(() => {
    const url = `${props.constants.hostname}/api/music`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then((data) => {
      if (!data) { return; }
        setSongs(data)
        setFiltered(data)
    })
    .catch((error) => { console.error(error); });
  }, [])

  function handleSearch(event) {
    const filteredSongs = [];
    let idx = 0;
    const searchVal = event.target.value.toLowerCase();
    songs.filter((song) => {
      if (song.Title.toLowerCase().includes(searchVal)) {
        filteredSongs[idx] = song;
        idx++;
      }
    return filteredSongs;
    })
    setFiltered(filteredSongs)
  }

  return (
    <div id='Music List'>
      <h3>Music</h3>
      <input
        onChange={(e) => handleSearch(e)}
        className={`${props.cssOff ? '' : 'songSearch'}`}
        placeholder='Search for songs...'
      />
      <table>
        <tbody>
        {filtered.map((song) =>
          <tr key={song.Slug}>
            <td>
              <div>
                <Link className={`${props.cssOff ? '' : 'clickable musicListEntry'}`} to={`/music/${song.Slug}`}>{song.Title}</Link>
              </div>
            </td>
          </tr>
        )}
        </tbody>
      </table>
    </div>
  );
}
