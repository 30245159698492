import { THEME } from './App';

const light = {
    '--background': 'white',
    '--navbar': '#222',
    '--headers': '#222',
    '--text': '#222',
    '--link': 'darkblue',
    '--button-background': '#222',
    '--button-text': '#9d9d9d',
    '--button-highlight': 'black',
    '--tr-hover': '#FFA300',
}

const dark = {
    '--background': 'black',
    '--navbar': '#222',
    '--headers': 'white',
    '--text': 'white',
    '--link': 'lightblue',
    '--button-background': '#222',
    '--button-text': 'white',
    '--button-highlight': '#444',
    '--tr-hover': 'grey',
    '--calendar-filter': 'invert(.9) saturate(0.5) hue-rotate(145deg)',
} 

const red = {
    '--background': 'lightcoral',
    '--navbar': 'darkred',
    '--headers': 'maroon',
    '--text': 'black',
    '--link': 'brown',
    '--button-background': 'darkred',
    '--button-text': 'lightcoral',
    '--button-highlight': 'brown',
    '--tr-hover': 'brown',
    '--calendar-filter': 'hue-rotate(140deg)',
} 

const blue = {
    '--background': 'powderblue',
    '--navbar': 'darkblue',
    '--headers': 'darkblue',
    '--text': 'darkblue',
    '--link': 'blue',
    '--button-background': 'darkblue',
    '--button-text': 'powderblue',
    '--button-highlight': 'cornflowerblue',
    '--tr-hover': 'cornflowerblue',
} 

const green = {
    '--background': 'lightgreen',
    '--navbar': 'darkgreen',
    '--headers': 'green',
    '--text': 'darkgreen',
    '--link': 'green',
    '--button-background': 'darkgreen',
    '--button-text': 'lightgreen',
    '--button-highlight': 'green',
    '--tr-hover': '#28a428',
    '--calendar-filter': 'hue-rotate(75deg);',
} 

const purple = {
    '--background': 'lavender',
    '--navbar': 'indigo',
    '--headers': 'indigo',
    '--text': 'indigo',
    '--link': 'rebeccapurple',
    '--button-background': 'indigo',
    '--button-text': 'lavender',
    '--button-highlight': 'orchid',
    '--tr-hover': 'orchid',
    '--calendar-filter': 'hue-rotate(44deg)',
} 

const prismarine = {
    '--background': '#0e4b40',
    '--navbar': '#03312e',
    '--headers': '#64ED8F',
    '--text': 'white',
    '--link': '#8fd9d4',
    '--button-background': '#03312e',
    '--button-text': '#8fd9d4',
    '--button-highlight': 'lightcoral',
    '--tr-hover': 'lightcoral',
    '--calendar-filter': 'hue-rotate(145deg)',
}

const brown = {
    '--background': '#5e3023',
    '--navbar': '#c08552',
    '--headers': 'sandybrown',
    '--text': 'burlywood',
    '--link': 'silver',
    '--button-background': 'silver',
    '--button-text': '#5e3023',
    '--button-highlight': 'peachpuff',
    '--tr-hover': 'peru',
}

const navy = {
    '--background': '#002b45',
    '--navbar': '#1d5279',
    '--headers': 'paleturquoise',
    '--text': 'white',
    '--link': 'steelblue',
    '--button-background': '#1d5279',
    '--button-text': 'paleturquoise',
    '--button-highlight': 'steelblue',
    '--tr-hover': 'navy',
}

const rainbow = {
    '--background': 'linear-gradient(90deg, rgb(255, 230, 230) 3%, rgb(255, 243, 228) 13%, rgb(254, 255, 231) 25%, rgb(238, 255, 235) 38%, rgb(231, 251, 253) 50%, rgb(231, 240, 255) 63%, rgb(240, 236, 255) 75%, rgb(255, 237, 255) 88%, rgb(255, 228, 246) 100%)',
    '--background-blow-up': '200% 200%',
    '--navbar': 'linear-gradient(90deg, rgba(255,164,164,1) 0%, rgba(255,214,165,1) 5%, rgba(253,255,182,1) 10%, rgba(202,255,191,1) 15%, rgba(155,246,255,1) 20%, rgba(160,196,255,1) 25%, rgba(189,178,255,1) 30%, rgba(240,190,255,1) 35%, rgba(255,198,255,1) 40%, rgba(255,185,202,1) 45%, rgba(255,164,164,1) 50%, rgba(255,214,165,1) 55%, rgba(253,255,182,1) 60%, rgba(202,255,191,1) 65%, rgba(155,246,255,1) 70%, rgba(160,196,255,1) 75%, rgba(189,178,255,1) 80%, rgba(240,190,255,1) 85%, rgba(255,198,255,1) 90%, rgba(255,185,202,1) 95%, rgba(255,164,164,1) 100%)',
    '--headers': 'black',
    '--text': 'black',
    '--link': 'purple',
    '--button-background': 'linear-gradient(90deg, rgba(255,164,164,1) 0%, rgba(255,214,165,1) 5%, rgba(253,255,182,1) 10%, rgba(202,255,191,1) 15%, rgba(155,246,255,1) 20%, rgba(160,196,255,1) 25%, rgba(189,178,255,1) 30%, rgba(240,190,255,1) 35%, rgba(255,198,255,1) 40%, rgba(255,185,202,1) 45%, rgba(255,164,164,1) 50%, rgba(255,214,165,1) 55%, rgba(253,255,182,1) 60%, rgba(202,255,191,1) 65%, rgba(155,246,255,1) 70%, rgba(160,196,255,1) 75%, rgba(189,178,255,1) 80%, rgba(240,190,255,1) 85%, rgba(255,198,255,1) 90%, rgba(255,185,202,1) 95%, rgba(255,164,164,1) 100%)',
    '--button-text': 'Black',
    '--button-highlight': 'beige',
    '--tr-hover': 'orchid',
    '--background-animation': 'gradient 10s infinite linear',
}

const ace = {
    '--background': 'black',
    '--navbar': 'linear-gradient(90deg, #181818 10%, #A3A3A3 30% 40%, #FFFFFF 50% 60%, #800080 80%)',
    '--headers': 'white',
    '--text': 'white',
    '--link': '#800080',
    '--button-background': '#800080',
    '--button-text': 'black',
    '--button-highlight': '#A3A3A3',
    '--tr-hover': '#A3A3A3',
    '--calendar-filter': 'hue-rotate(44deg) invert(.75) saturate(1) hue-rotate(170deg)',
} 

const bi = {
    '--background': '#e6bce3',
    '--navbar': 'linear-gradient(90deg, #D60270 30%, #9B4F96 45% 55%, #0038A8 70%)',
    '--headers': 'white',
    '--text': 'white',
    '--link': '#0038A8',
    '--button-background': '#D60270',
    '--button-text': 'white',
    '--button-highlight': 'lightskyblue',
    '--tr-hover': '#0038A8',
    '--calendar-filter': 'invert(100%) saturate(100%) invert(100%) sepia(21%) saturate(4977%) hue-rotate(215deg) brightness(95%) contrast(90%)',
}

const enby = {
    '--background': 'black',
    '--navbar': 'linear-gradient(90deg, #FFF430 10%, #FFFFFF 30% 40%, #9C59D1 60% 80%, #181818 90%)',
    '--headers': '#FFF430',
    '--text': 'white',
    '--link': '#9C59D1',
    '--button-background': '#9C59D1',
    '--button-text': 'black',
    '--button-highlight': '#FFF430',
    '--tr-hover': '#FFF430',
}

const trans = {
    '--background': 'white',
    '--navbar': 'linear-gradient(90deg, #5BCEFA 10%, #F5A9B8 22.5% 32.5%,  #FFFFFF 45% 55%, #F5A9B8 67.5% 77.5%, #5BCEFA 90%)',
    '--headers': '#F5A9B8',
    '--text': 'grey',
    '--link': '#5BCEFA',
    '--button-background': '#F5A9B8',
    '--button-text': 'black',
    '--button-highlight': '#5BCEFA',
    '--tr-hover': '#5BCEFA',
}

const pan = {
    '--background': '#80d2ff',
    '--navbar': 'linear-gradient(90deg, #FF218C 10%,  #FFD800 45% 55%, #21B1FF 90%)',
    '--headers': '#ffa600',
    '--text': '#FF218C',
    '--link': '#FF218C',
    '--button-background': '#FFD800',
    '--button-text': 'black',
    '--button-highlight': '#FF218C',
    '--tr-hover': '#21B1FF',
    '--calendar-filter': 'hue-rotate(197deg)',
}

const mlm = {
    '--background': '#9debde',
    '--navbar': 'linear-gradient(90deg, #00916e 10%, #00d2a9 15% 25%, #7debbe 30% 40%, #efefef 45% 55%, #6eaee7 60% 70%, #5443d3 75% 85%, #43117d 90%)',
    '--headers': '#43117d',
    '--text': '#5443d3',
    '--link': '#0038A8',
    '--button-background': '#5443d3',
    '--button-text': 'black',
    '--button-highlight': '#00916e',
    '--tr-hover': '#0038A8',
}

const wlw = {
    '--background': 'pink',
    '--navbar': 'linear-gradient(90deg, red 0 10%, #ff7000 15% 25%, #f93 30% 40%, #efefef 45% 55%, #f5a 60% 70%, #f49 75% 85%, #e06 90%)',
    '--headers': '#ff7000',
    '--text': '#f93',
    '--link': '#ff7000',
    '--button-background': '#e06',
    '--button-text': 'black',
    '--button-highlight': '#ff7000',
    '--tr-hover': '#f93',
}

const patrick = {
    '--background': '#363a4f',
    '--navbar': '#181926',
    '--headers': '#8087a2',
    '--text': '#8087a2',
    '--link': '#ed8796',
    '--button-background': '#181926',
    '--button-text': '#9d9d9d',
    '--button-highlight': 'black',
    '--tr-hover': '#181926',
    '--calendar-filter': 'brightness(0.65) saturate(15) hue-rotate(125deg)',
    '--global-font': 'italic 1.2rem Fira Sans,serif',
}

const jensen = {
    '--background': 'black',
    '--navbar': '#008F28',
    '--headers': '#008F28',
    '--text': 'grey',
    '--link': '#5339FF',
    '--button-background': '#008F28',
    '--button-text': 'lightgreen',
    '--button-highlight': 'black',
    '--tr-hover': '#856514',
    '--calendar-filter': 'invert(1) saturate(0.5) hue-rotate(60deg)',
    '--global-font': 'bold 1.2rem courier new',
}

const munson = {
    '--background': 'white',
    '--navbar': '#F76902',
    '--headers': 'black',
    '--text': '#7c878e',
    '--link': '#009cbd',
    '--button-background': '#7d55c7',
    '--button-text': 'white',
    '--button-highlight': '#009cbd',
    '--tr-hover': '#009cbd',
}

const spooky = {
    '--background': 'black',
    '--navbar': 'orange',
    '--headers': 'orange',
    '--text': 'white',
    '--link': 'magenta',
    '--button-background': 'magenta',
    '--button-text': 'black',
    '--button-highlight': 'white',
    '--tr-hover': 'orange',
    '--calendar-filter': 'invert(1) saturate(0.5) hue-rotate(60deg)',
    '--global-font': 'bold 1.2rem Garamond',
}

const holidays = {
    '--background': '#d65a3c',
    '--navbar': '#325632',
    '--headers': 'white',
    '--text': '#48391c',
    '--link': '#a7fab4',
    '--button-background': '#325632',
    '--button-text': 'silver',
    '--button-highlight': '#ef4b2f',
    '--tr-hover': '#ef4b2f',
    '--calendar-filter': 'hue-rotate(140deg)',
    '--global-font': 'italic 1.2rem Brush Script MT',
}

const winterBackground = `radial-gradient(circle at -1% -10%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -4% 0%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 2% 25%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -2% 22%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 9% 49%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -1% 51%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 2% 63%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -8% 78%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 4% 85%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 0% 95%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 7% -4%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 11% 15%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 2% 19%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 17% 27%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 16% 37%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 1% 47%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 4% 60%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 16% 77%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 3% 82%, white 9px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 17% 80%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 18% -6%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 12% 5%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 29% 27%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 19% 37%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 21% 44%, white 9px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 24% 49%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 19% 57%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 14% 71%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 25% 77%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 15% 91%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 24% -1%, white 9px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 20% 8%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 31% 18%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 36% 37%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 23% 49%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 35% 58%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 26% 58%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 20% 79%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 38% 72%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 27% 82%, white 5px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 35% -7%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 33% 1%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 37% 25%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 47% 40%, white 5px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 46% 37%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 36% 46%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 48% 53%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 41% 75%, white 5px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 48% 74%, white 5px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 43% 84%, white 9px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 40% 7%, white 9px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 42% 5%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 48% 30%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 50% 24%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 59% 37%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 50% 41%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 59% 50%, white 5px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 56% 62%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 43% 70%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 42% 99%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 64% -9%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 64% 9%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 67% 14%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 67% 40%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 54% 35%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 67% 54%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 68% 59%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 57% 77%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 67% 75%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 66% 91%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 63% -2%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 77% 11%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 72% 23%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 79% 28%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 66% 38%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 80% 45%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 61% 51%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 78% 78%, white 9px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 60% 77%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 63% 82%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 79% 5%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 80% 13%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 86% 26%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 75% 26%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 75% 34%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 76% 53%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 78% 66%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 83% 78%, white 9px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 85% 72%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 84% 89%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 91% 5%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 100% 17%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 94% 16%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 88% 23%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 86% 46%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 87% 58%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 99% 58%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 95% 63%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 88% 83%, white 5px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 98% 95%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -101% -10%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -1% -110%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -104% 0%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -4% -100%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -98% 25%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 2% -75%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -102% 22%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -2% -78%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -91% 49%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 9% -51%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -101% 51%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -1% -49%, white 1px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -98% 63%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 2% -37%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -108% 78%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -8% -22%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -96% 85%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 4% -15%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -100% 95%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 0% -5%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -93% -4%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 7% -104%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -98% 19%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 2% -81%, white 3px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -99% 47%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 1% -53%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -96% 60%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 4% -40%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -97% 82%, white 9px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 3% -18%, white 9px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 15% -9%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 42% -1%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 66% -9%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -9% 5%, white 4px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 0% 17%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -6% 16%, white 6px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -1% 58%, white 2px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -5% 63%, white 8px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at -2% 95%, white 7px, rgba(255, 0, 0, 0) 10px),
radial-gradient(circle at 98% -5%, white 7px, rgba(255, 0, 0, 0) 10px), darkgrey`

const winter = {
    '--background': winterBackground,
    '--background-blow-up': '750px 750px',
    '--background-animation': 'gradient 20s infinite linear',
    '--navbar': 'sienna',
    '--headers': 'white',
    '--text': 'white',
    '--link': 'steelblue',
    '--button-background': 'dimgray',
    '--button-text': 'lightgrey',
    '--button-highlight': 'steelblue',
    '--tr-hover': 'steelblue',
}

export const THEMELIST = {default: 'Default',
                          light: 'Light',
                          dark: 'Dark', 
                          red: 'Red', 
                          blue: 'Blue', 
                          green: 'Green', 
                          purple: 'Purple', 
                          prismarine: 'Prismarine',
                          brown: 'Brown',
                          navy: 'Navy',
                          patrick: 'Patrick',
                          jensen: 'Jensen',
                          munson: 'Munson',
                          rainbow: 'Rainbow', 
                          trans: 'Trans', 
                          enby: 'Enby', 
                          bi: 'Bi', 
                          ace: 'Ace', 
                          pan: 'Pan',
                          mlm: 'Mlm',
                          wlw: 'Wlw',
                          spooky: 'Spooky',
                          holidays: 'Holidays',
                          winter: 'Winter'}

export const THEMEOBJECTS = {Light: light,
                Dark: dark, 
                Red: red, 
                Blue: blue, 
                Green: green, 
                Purple: purple, 
                Prismarine: prismarine,
                Brown: brown,
                Navy: navy,
                Rainbow: rainbow,
                Trans: trans,
                Enby: enby,
                Bi: bi,
                Ace: ace,
                Pan: pan,
                Mlm: mlm,
                Wlw: wlw,
                Patrick: patrick,
                Jensen: jensen,
                Munson: munson,
                Spooky: spooky,
                Holidays: holidays,
                Winter: winter,
            }

export default function themeLoader() {
    const root = document.querySelector(':root')
    try {
        let theme = localStorage.getItem(THEME);
        if(theme === THEMELIST.default) {
            theme = THEMELIST.light
        }
        const themeObject = THEMEOBJECTS[theme]
        Object.keys(themeObject).forEach((key) => {
            const value = themeObject[key];
            root.style.setProperty(key, value);
        });
    } catch (e) { // If Error, print out and load Light Mode
        console.error(e)
        Object.keys(THEMEOBJECTS.Light).forEach((key) => {
            const value = light[key];
            root.style.setProperty(key, value);
        });
    }
}